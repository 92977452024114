// Uncomment this part for Development
const DOMAIN = "localhost";
const PORT = "2004";

// export const API_URL = `http://${DOMAIN}:${PORT}`;
export const API_URL = 'https://fp1-api.purwadhikabootcamp.com';

// Uncomment this part for Production
// Change DOMAIN according to the given domain for your batch
// const DOMAIN = "purwadhikajcwd"

// Change SUBDOMAIN according to your group number and final project spec
// Format: groupNumber-spec
// Example: 3-parcel
// const SUBDOMAIN = "1-warehouse"

// export const API_URL = `http://${SUBDOMAIN}.${DOMAIN}.com`
